@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

html {
    height: 100%;
}

body {
    font-family: 'Roboto Condensed', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: rgb(242, 255, 145);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(242, 255, 145, 0.9)), to(rgba(122, 193, 177, 0.7))) fixed;
}


.sidenav {
    height: 100%;
    width: 5rem;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #f7f7f7;
    overflow-x: hidden;
    padding-top: 20px;
}

.sidenav a {
    padding: 0.7rem 6px 0.7rem 16px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
}


.main {
    margin-top: 2rem;
    margin-left: 7rem;
}

@media only screen and (max-width: 426px) {
    .sidenav {
        /*display: none;*/

        height: 5rem;
        width: 100%;
        position: fixed;
        z-index: 1;
        /*top: 0;*/
        /*left: 0;*/
        background-color: #f7f7f7;
        overflow-y: hidden;
        /*padding-top: 20px;*/

    }

    .sidenav a {
        padding: 6px 6px 6px 6px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
    }

    .main {
        margin-top: 7rem;
        margin-left: 5px;
    }
}


.category {

}

.category > a > .tab {
    margin: 1rem 0;
    min-height: 10rem;
}

.category > .tab:hover {
    box-shadow: none;
}


.taskSelector {
    /*border: 1px solid #2e2e2e;*/
    width: max-content;
    max-width: 49rem;
    display: flex;
    flex-wrap: wrap;
}

.tab {
    margin: 1rem;
    padding: 1rem;
    font-size: 3rem;
    /*background-color: #ebebeb;*/
    border: 1px solid #2e2e2e;
    border-radius: 5px;
    text-align: center;
    min-height: 10rem;
    min-width: 10rem;
}

.achievementSelector {
    width: max-content;
    max-width: 49rem;
    display: flex;
    flex-wrap: wrap;
}

.achievementTab {
    position:relative;
    margin: 3rem;
    background-color: #ebebeb;
    text-align: center;
    min-height: 10rem;
    min-width: 10rem;
}

.achievementTab:hover {
    top: 5px;
}

.tab:hover {
    background-color: rgba(122,193,177, 0.3);
}

.greyed {
    opacity: .5;
    background-color: rgba(20, 20, 20, 0.3);
}


/* Animations*/

.fadein {
    animation: fadein 2s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.fadeout {
    animation: fadeout 30s;
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/** Intro */

.intro-skip {
    position: fixed;
    margin-top: 15px;
    margin-right: 15px;
    top: 0;
    right: 0;
    z-index: 999;
}

.intro-earth {
    margin: 5rem;
    z-index: 1;
    -webkit-animation: spin 10s linear 3;
    -moz-animation:spin 10s linear 3;
    animation:spin 10s linear 3;
}

.intro-clouds {
    position: absolute;
    z-index: 2;
}

.intro-stats {
    position: absolute;
    z-index: 3;
}

.intro-play {
    margin: 3rem 2rem 0;
    text-align: center;
}

.intro-play > h1 {
    font-size: 10vw;
    font-weight: bold;
}

.intro-play > h2 {
    font-size: 4vw;
}

.intro-play > h3 {
    font-size: 2vw;
}
